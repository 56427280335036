.splash {
  background: url(/images/splash.jpg);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: 100%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  max-height: 96vh;
  min-height: 96vh;

  @media only screen and (max-width: 768px) {
    background-position: 80%;
  }

  @media only screen and (max-width: 480px) {
    background-position: 75%;
  }

  .title {
    position: absolute;
    top: 35%;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 80%;
    max-width: 420px;
    color: $alabaster;

    h1 {
      text-shadow: 1px 1px 1px #000000;
    }

    p {
      text-shadow: 1px 1px 2px #000000;
    }
  }

  a {
    color: $alabaster;
  }
}
