#search-container {
  padding-top: 0px;
  padding-bottom: 0px;

  #search-input {
    background-color: transparent;
    color: $alabaster;
    border: none;
    width: 100%;
    line-height: 1.66667;
    padding-top: 10px;
    padding-left: 0px;
    padding-right: 0px;
    margin-bottom: 5px;
  }

  #results-container {
    position: absolute;
    background-color: #3d3d3d;
    color: $alabaster;
    z-index: 999;
    padding-left: 10px;
    left: -10px;
    right: -10px;
    font-size: 14px;
    a {
      color: $alabaster;
      border-left-width: 0px !important;
      padding-left: 0px !important;
    }
    li {
      float: none;
    }
  }
}
